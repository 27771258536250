




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import { SavingsServiceType } from '@/vue-app/types';

@Component({ name: 'PrepareDocuments' })
export default class PrepareDocuments extends Vue {
  @Prop(String)
  readonly service!: SavingsServiceType;

  i18n_namespace = 'components.contract-savings.before-start.prepare_documents';

  small_screen: null | boolean = null;

  mounted() {
    this.small_screen = getScreenSizeVariant() === 'small';
  }
}

